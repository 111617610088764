.BulkAssgn__grid {
  display: grid;
  grid-template-columns: 20px 6fr 3px 6fr 20px;
  grid-template-rows: 10px minmax(370px, 5fr) minmax(min-content, max-content) minmax(min-content, max-content) 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 20px;
  background-color: #ffffff;
}

.BulkAssgn__SelectSites {
  grid-row: 2/2;
  grid-column: 2/2;
}

.BulkAssgn__SelectUsers {
  grid-row: 3/3;
  grid-column: 2/2;
}

.BulkAssgn__SitesPool {
  padding-left: 20px;
  padding-right: 20px;
  grid-row: 2/2;
  grid-column: 4/4;
}

.BulkAssgn__UsersPool {
  padding-left: 20px;
  padding-right: 20px;
  grid-row: 3/3;
  grid-column: 4/4;
}

.BulkAssgn__CentralBar {
  border-left: 3px solid #000000;
  grid-row: 2/5;
  grid-column: 3/3;
}

.BulkAssgn__Site__Selector {
  grid-row: 1/1;
  grid-column: 2/2;
}

.BulkAssgn__EnableCheckboxes {
  grid-row: 4/4;
  grid-column: 2/2;
  display: flex;
  align-items: center;
}

.BulkAssgn__ReportCheckboxes {
  grid-row: 4/4;
  grid-column: 4/4;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.BulkAssgn__ReportCheckboxes--inner {
  display: flex;
  align-items: center;
}

.BulkAssgn__ReportCheckbox--holder {
  margin-right: 32px;
}

.BulkAssgn__Buttons {
  grid-row: 5/5;
  grid-column: 4/4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BulkAssgn__CancelButton {
  margin: 0px 20px 0px 20px;
}

.BulkAssgn__Label {
  color: #7F8FA4;
}

.BulkUnassgn__Pool {
  grid-row: 2/4;
  grid-column:4/4;
}
